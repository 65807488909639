import React from "react"
import {
  FaTwitter,
  FaInstagram,
  FaGithub,
  FaLinkedin,
  FaDev,
} from "react-icons/fa"

import styles from "./social-media.module.scss"

const SocialMedia = ({ siteTitle }) => (
  <div>
    <div className={styles.icons_container}>
      <a
        href="https://twitter.com/xw92"
        className={styles.icon_link}
        title="Xinran's Twitter account"
      >
        <FaTwitter className={styles.icon} />
      </a>
      <a
        href="https://www.instagram.com/theflytrap/"
        className={styles.icon_link}
        title="Xinran's Instagram account"
      >
        <FaInstagram className={styles.icon} />
      </a>
      <a
        href="https://github.com/xinranw"
        className={styles.icon_link}
        title="Xinran's GitHub account"
      >
        <FaGithub className={styles.icon} />
      </a>
      <a
        href="https://www.linkedin.com/in/xinranw"
        className={styles.icon_link}
        title="Xinran's LinkedIn account"
      >
        <FaLinkedin className={styles.icon} />
      </a>
      <a
        href="https://dev.to/xinranw"
        className={styles.icon_link}
        title="Xinran's Dev.to account"
      >
        <FaDev className={styles.icon} />
      </a>
    </div>
  </div>
)

export default SocialMedia
