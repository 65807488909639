// import { Link } from "gatsby"
import React from "react"
import SocialMedia from "../social-media/social-media"

import styles from "./sidebar.module.css"

const Sidebar = ({ siteTitle }) => (
  <div className={styles.container}>
    <div>Welcome!</div>
    <h1 className={styles.title}>Xinran Wang</h1>
    <h2 className={styles.subtitle}>Tech + Food</h2>
    <SocialMedia />
  </div>
)

export default Sidebar
