import React from "react"
// import { Link } from "gatsby"

import Sidebar from "../components/sidebar/sidebar"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailListForm from "../components/email-list-form/email-list-form"

import styles from "./index.module.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="XINRAN WANG" />
    <div className={styles.main_container}>
      <div className={styles.container} style={{ flex: "2 2" }}>
        <Sidebar />
      </div>
      <div
        className={`${styles.container} ${styles.content_container}`}
        style={{ flex: "3 3" }}
      >
        <div className={styles.card}>
          <p className={styles.text_block}>
            Hi there! I'm Xinran, currently an iOS engineer at Lyft.
          </p>
          <p className={styles.text_block}>
            In the past, I've worked for a variety of teams and companies,
            helping bring their ideas to life. In 2015, I built the first
            iteration of <a href="https://www.getleaf.co/">Leaf</a> app,
            showcased at{" "}
            <a href="https://techcrunch.com/2015/09/22/leaf-is-a-plug-n-plant-box-to-automatically-grow-pot-in-your-home/">
              Techcrunch Disrupt SF 2015
            </a>
            . Most recently, I helped Lyft launch their new membership program:{" "}
            <a href="https://blog.lyft.com/posts/introducing-lyft-pink">
              Lyft Pink
            </a>
            .
          </p>
          <p className={styles.text_block}>
            {/* Along the way, I have worked on a variety of projects as an
            engagement lead, designer, devops engineer, software developer,
            manager. */}
            Outside of my day job, I also love to cook! I really enjoy
            recreating dishes from around the world. As someone from a country
            with a long history of food, I do my best to respect the cultures
            behind every dish I make. Follow along on my instagram account!
          </p>
          <p className={styles.text_block}>
            Want to follow along on my journey? Sign up for my email list below!
          </p>
        </div>
        {/* <form method="post" action="#" className={styles.input}>
            <input
              className={styles.input__field}
              type="email"
              name="email"
              id="email"
              placeholder="Your email here (you@example.com)"
            ></input>
            <button
              type="submit"
              className={`${styles.button} ${styles.btn} ${styles.btn_3}`}
            >
              <FaArrowRight id={styles.arrow} size="1.33rem"></FaArrowRight>
            </button>
          </form> */}
        <div className={styles.card}>
          <EmailListForm />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
