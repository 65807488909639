import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

import "./email-list-form.scss"

class EmailListForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: "", result: { msg: "", result: "" } }
  }

  componentDidMount() {
    // const script = document.createElement("script")
    // script.src = "./validation.js"
    // script.async = true
    // script.type = "script/javascript"
    // console.log(script)
    // document.body.appendChild(script)
  }

  handleSubmit = async e => {
    e.preventDefault()
    console.log(this.state.email)
    const result = await addToMailchimp(this.state.email)
    console.log(result)
    this.setState({
      result: result,
    })
  }

  handleEmailChange = event => {
    this.setState({
      email: event.currentTarget.value,
    })
  }

  render() {
    const mcStatusMessage = this.state.result.msg.toString()
    const mcStatusClass =
      this.state.result.result === "error" ? "error-message" : "success-message"

    return (
      <form
        onSubmit={this.handleSubmit}
        method="post"
        className="email-list-form"
        target="_blank"
      >
        <h3>Subscribe to my email list!</h3>
        <div className="wrapper">
          <input
            type="email"
            name="EMAIL"
            id="email"
            placeholder="Email address"
            // title="The domain portion of the email address is invalid (the portion after the @)."
            // pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
            onChange={this.handleEmailChange}
            required
          ></input>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_f2d244c0df42a0431bd08ddea_aeaa9dd034"
              tabIndex="-1"
              value=""
            />
          </div>
          <button type="submit" name="subscribe">
            Subscribe
          </button>
        </div>
        <div className={`mc-status ${mcStatusClass}`}>
          <div dangerouslySetInnerHTML={{ __html: mcStatusMessage }} />
        </div>
      </form>
    )
  }
}

export default EmailListForm
